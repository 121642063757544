import LogoText from "../SVGs/LogoText";
import { HeaderStyles } from "../Header/Header.styles";
import { A } from "../Typography/Typography.index";

function Header() {
	return (
		<HeaderStyles id="header-global">
			<A to="/theme-board">
				<LogoText />
			</A>
		</HeaderStyles>
	);
}

export default Header;
