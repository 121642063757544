import { FooterMenuContainer, FooterMenuItem } from "./FooterMenu.styles";

function FooterMenu() {
    return (
        <FooterMenuContainer>
            <FooterMenuItem to="/faq">Support</FooterMenuItem>
            <FooterMenuItem to="/terms">Terms</FooterMenuItem>
            <FooterMenuItem to="/terms">Privacy</FooterMenuItem>
        </FooterMenuContainer>
    );
}

export default FooterMenu;
