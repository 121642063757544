import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../../InitFirebase"

export function Username() {
    const [user] = useAuthState(auth as any)
    const [username, setUsername] = useState("cool cat");

    useEffect(() => {
		if (user) {
		const userDisplayName = user?.displayName as string;
		setUsername(userDisplayName || "cool cat");
		}
	}, [user]);

    return (
        <>
            {username}
        </>
    );
}