// Social media icon logos as raw SVGs

import styled from "styled-components";
import { Svg } from "./Svg.styles";

const SocialIconSvg = styled(Svg)`
  height: 2rem;
`;

export function TwitterIcon() {
    return (
        <SocialIconSvg viewBox="0 0 246.14 200">
            <path d="M220.94,49.79c.15,2.17,.15,4.34,.15,6.53,0,66.73-50.8,143.68-143.68,143.68v-.04c-27.44,.04-54.31-7.82-77.41-22.64,3.99,.48,8,.72,12.02,.73,22.74,.02,44.83-7.61,62.72-21.66-21.61-.41-40.56-14.5-47.18-35.07,7.57,1.46,15.37,1.16,22.8-.87-23.56-4.76-40.51-25.46-40.51-49.5v-.64c7.02,3.91,14.88,6.08,22.92,6.32C10.58,61.81,3.74,32.29,17.14,9.21c25.64,31.55,63.47,50.73,104.07,52.76-4.07-17.54,1.49-35.92,14.61-48.25,20.34-19.12,52.33-18.14,71.45,2.19,11.31-2.23,22.15-6.38,32.07-12.26-3.77,11.69-11.66,21.62-22.2,27.93,10.01-1.18,19.79-3.86,29-7.95-6.78,10.16-15.32,19.01-25.2,26.16Z"/>
        </SocialIconSvg>
    );
}

export function TikTokIcon() {
    return (
        <SocialIconSvg viewBox="0 0 174.41 200">
            <path d="M148.36,40.09c-10.77-7.02-18.55-18.26-20.97-31.36-.52-2.83-.81-5.74-.81-8.72h-34.38l-.06,137.8c-.58,15.43-13.28,27.82-28.85,27.82-4.84,0-9.4-1.21-13.41-3.32-9.2-4.84-15.5-14.48-15.5-25.58,0-15.94,12.97-28.9,28.9-28.9,2.98,0,5.83,.49,8.53,1.34v-35.1c-2.79-.38-5.63-.62-8.53-.62C28.39,73.42,0,101.82,0,136.71c0,21.41,10.7,40.36,27.02,51.82,10.28,7.22,22.78,11.47,36.27,11.47,34.9,0,63.29-28.39,63.29-63.29V66.84c13.49,9.68,30.01,15.38,47.84,15.38V47.84c-9.6,0-18.55-2.86-26.05-7.75Z"/>
        </SocialIconSvg>
    );
}

export function InstagramIcon() {
    return (
        <SocialIconSvg viewBox="0 0 200 200">
            <path d="M58.58,.7c-10.64,.5-17.91,2.2-24.26,4.7-6.57,2.56-12.15,6-17.69,11.56-5.54,5.56-8.96,11.14-11.5,17.72-2.46,6.37-4.13,13.64-4.6,24.28C.06,69.61-.04,73.04,.01,100.19c.05,27.16,.17,30.56,.69,41.23,.51,10.64,2.2,17.9,4.7,24.26,2.57,6.57,6,12.14,11.57,17.69,5.57,5.55,11.14,8.95,17.74,11.5,6.36,2.46,13.63,4.13,24.28,4.6,10.64,.47,14.07,.57,41.22,.52,27.15-.05,30.57-.17,41.23-.68s17.89-2.21,24.25-4.69c6.57-2.57,12.15-6,17.69-11.57,5.54-5.57,8.95-11.15,11.5-17.74,2.46-6.36,4.14-13.63,4.6-24.27,.47-10.67,.58-14.08,.52-41.23s-.17-30.56-.68-41.22c-.51-10.66-2.2-17.91-4.69-24.26-2.57-6.57-6-12.14-11.56-17.69-5.56-5.55-11.15-8.96-17.73-11.5-6.36-2.46-13.63-4.14-24.28-4.6C130.39,.08,126.97-.04,99.81,.01c-27.16,.05-30.56,.17-41.22,.69m1.17,180.78c-9.75-.42-15.04-2.04-18.57-3.4-4.67-1.8-8-3.98-11.52-7.46-3.52-3.48-5.68-6.82-7.5-11.48-1.37-3.53-3.02-8.82-3.48-18.57-.5-10.54-.6-13.7-.66-40.4-.06-26.7,.04-29.86,.51-40.4,.42-9.74,2.05-15.04,3.4-18.57,1.8-4.68,3.97-8,7.46-11.51s6.82-5.68,11.49-7.5c3.52-1.38,8.81-3.01,18.56-3.48,10.55-.5,13.71-.6,40.4-.66,26.69-.06,29.86,.04,40.41,.51,9.74,.42,15.04,2.04,18.57,3.4,4.67,1.8,8,3.96,11.51,7.46s5.68,6.81,7.5,11.49c1.38,3.51,3.01,8.8,3.47,18.55,.5,10.55,.62,13.71,.66,40.4,.05,26.69-.05,29.86-.51,40.4-.43,9.75-2.04,15.05-3.4,18.58-1.8,4.67-3.97,8-7.46,11.51s-6.82,5.68-11.49,7.5c-3.52,1.37-8.81,3.01-18.55,3.48-10.55,.5-13.71,.6-40.41,.66-26.7,.06-29.85-.05-40.4-.51M141.27,46.55c.01,6.63,5.39,11.99,12.02,11.98s11.99-5.39,11.98-12.02c-.01-6.63-5.39-11.99-12.02-11.98,0,0,0,0,0,0-6.63,.01-11.99,5.39-11.98,12.02M48.65,100.1c.06,28.36,23.09,51.3,51.44,51.24,28.35-.05,51.31-23.08,51.25-51.44s-23.09-51.3-51.45-51.25c-28.36,.06-51.3,23.09-51.25,51.45m18.01-.04c-.04-18.41,14.86-33.36,33.27-33.4,18.41-.04,33.36,14.86,33.4,33.27,.04,18.41-14.86,33.36-33.27,33.4-18.41,.04-33.36-14.85-33.4-33.26h0"/>
        </SocialIconSvg>
    );
}

export function FacebookIcon() {
    return (
        <SocialIconSvg viewBox="0 0 201.29 200">
            <path d="M116.43,200v-70.21c2.6-.02,5.16,0,7.72,0,2.61,0,5.22,0,7.83,0h7.85c1.49-9.68,2.97-19.32,4.47-29.1-1.21-.1-2.36-.04-3.5-.05-1.16-.01-2.33,0-3.49,0h-20.82c-.03-.29-.09-.53-.09-.77,0-6.26-.04-12.52,.03-18.78,.02-1.52,.25-3.08,.64-4.56,1.33-5.14,4.67-8.35,9.77-9.73,2.2-.6,4.46-.73,6.73-.74,3.59-.01,7.17,0,10.76,0h1.09v-24.76c-.71-.12-1.44-.26-2.17-.36-6.23-.87-12.48-1.48-18.78-1.6-5.54-.11-10.99,.43-16.26,2.23-10.84,3.71-17.82,11.18-21.23,22.06-1.14,3.65-1.77,7.39-1.94,11.21-.08,1.76-.12,3.52-.13,5.28-.01,6.42,0,12.83,0,19.25,0,.37,0,.75,0,1.26-2.89,0-5.7,0-8.52,0-2.8,0-5.6,0-8.4,0h-8.45c-.21,.77-.29,27.17-.11,28.99,.02,.02,.04,.04,.06,.06,.02,.02,.05,.05,.07,.06,.03,.01,.06,.01,.09,.02,.06,0,.13,.02,.19,.02,8.27,0,16.54,.02,24.81,.03,.03,0,.06,.01,.09,.02,.2,.65,.27,68.73,.07,70.13C45.13,194.85,3.54,159.59,.21,107.06-3.44,49.5,41.39,1.54,97.92,.04c56.69-1.5,103.02,43.76,103.36,99.88,.33,54.36-41.06,94.07-84.86,100.08Z"/>
        </SocialIconSvg>
    );
}