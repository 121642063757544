import {getStorage} from 'firebase/storage';
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app"

import {connectFunctionsEmulator, getFunctions} from "firebase/functions";

const app = initializeApp({
    apiKey: "AIzaSyCbN6Aqf6PUx9LEQYqwpIsnGrXel2VdByU",
    authDomain: "ffetch-react.firebaseapp.com",
    projectId: "ffetch-react",
    storageBucket: "ffetch-react.appspot.com",
    messagingSenderId: "384131283787",
    appId: "1:384131283787:web:83ab7ff38201c7e88186ce",
    measurementId: "G-9LHGJDH22Q"
});


export const functions = getFunctions(app);

// NB THIS SHOULD NOT BE USED IN PRODUCTION.
if (process.env.NODE_ENV === "development") {
    console.warn("Running firebase functions in the emulator.");
    connectFunctionsEmulator(functions, "localhost", 6001);
}
// DO NOT USE THIS IN PRODUCTION.

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
