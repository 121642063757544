import {FacebookIcon, InstagramIcon, TikTokIcon, TwitterIcon} from "../SVGs/Socials";
import { SocialBarContainer } from "./SocialBar.styles";

function SocialBar() {

   return (
      <SocialBarContainer>
            <a href="https://www.tiktok.com/@ffetch.ai" target="_blank" rel="noopener noreferrer"
               title="FFETCH.ai TikTok"><TikTokIcon/></a>
            <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"
               title="FFETCH.ai Instagram"><InstagramIcon/></a>
            <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"
               title="FFETCH.ai Facebook"><FacebookIcon/></a>
            <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"
               title="FFETCH.ai Twitter"><TwitterIcon/></a>
      </SocialBarContainer>
   );
}

export default SocialBar;