import styled from "styled-components";
import { ButtonStyleProps } from "../../Styles/StyleInterfaces";

export const ButtonStyles = styled.button.attrs((props: any) => ({
    })) <ButtonStyleProps>`
    width: 10rem;
    height: 3rem;
    font-size: 1.5rem;
    border-radius: 0.45rem;
    color: ${props => props.theme.colors.text.contrast};
    background-color: ${props => props.theme.colors.background.contrast};
    font-size: ${props => props.theme.font.size.reg};
    background-color: ${props => props.design === "warning" ? "#ff7300" : props.theme.colors.background.contrast};
`;
