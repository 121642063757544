import { H6, H4 } from "../../../Typography/Typography.index";
import { JumpArrowContainer } from "./JumpArrow.styles";

function JumpArrow() {

    function scrollToTop() {
        const firstTarget = document.getElementById("header-global");
        firstTarget?.scrollIntoView({behavior: "smooth"});
    }

    return (
        <JumpArrowContainer onClick={scrollToTop}>
            <H4>🚀</H4>
            <H6>Jump to top</H6>
        </JumpArrowContainer>
    );
}

export default JumpArrow;