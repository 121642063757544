import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

function HelmetMeta() {

	const [faviconHref, setFaviconHref] = useState("/faviconBlack.ico");

	const getFaviconPath = (isDarkMode = false) => {
		return `/${isDarkMode ? "faviconWhite" : "faviconBlack"}.ico`;
	};

	useEffect(() => {
		const matcher = window.matchMedia("(prefers-color-scheme: dark)");
		if (!matcher) {
			return;
		}
		setFaviconHref(getFaviconPath(matcher.matches));
		matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches));
	}, [faviconHref]);

	return (
		<Helmet>
			<title>FFETCH.ai</title>
			<meta name="description" content="App Description" />
			<meta name="theme-color" content="#212121" />{" "}
			{/* Add OS prefers brains when build error unFudged */}
			<link rel="icon" href={faviconHref} />
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin=""/>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"/>
		</Helmet>
	);
}

export default HelmetMeta;
