import {Routes, Route} from "react-router-dom";
import Home from "./Home/Home";
import TextInput from "./AiTextInput/AiTextInput.index";
import ViewImages from "./ViewImages/ViewImages";
import CustomizeGarment from "./CustomiseGarment/CustomizeGarment";
import Checkout from "./Checkout/Checkout";
import OrderSuccess from "./OrderSuccess/OrderSuccess";
import Terms from "./Terms/Terms";
import Faq from "./Faq/Faq";
import OrderTracker from "./TrackOrder/TrackOrder";
import ProductBrowser from "./PrintfulBOH/PrintfulProductBrower";
import PrintfulProductView from "./PrintfulBOH/PrintfulProductView";
import ThemeBoard from "./ThemeBoard";
import { MainComponentContainer } from "../AppContainers/AppContainers.styles";
import NotFound from "./NotFound/NotFound.index";

function MainComponent() {
    return (
        <MainComponentContainer>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/text-input" element={<TextInput/>}/>
                <Route path="/view-images" element={<ViewImages/>}/>
                <Route path="/customize-garment" element={<CustomizeGarment/>}/>
                <Route path="/checkout" element={<Checkout/>}/>
                <Route path="/order-success" element={<OrderSuccess/>}/>
                <Route path="track-order" element={<OrderTracker/>}/>
                <Route path="terms" element={<Terms/>}/>
                <Route path="faq" element={<Faq/>}/>
                <Route path="product-browser" element={<ProductBrowser/>}/>
                <Route path="product-viewer" element={<PrintfulProductView/>}/>
                <Route path="*" element={<NotFound/>}/>
                {/* temp for dev */}
                <Route path="theme-board" element={<ThemeBoard/>}/>
            </Routes>
        </MainComponentContainer>
    );
}

export default MainComponent;