// Image results returned from text input, I think...

function ViewImages() {
    return (
        <>
            <h1>View Images</h1>
            <a href={"/customize-garment"}>
                <img width="500" src="https://i.imgur.com/doWQ7.jpeg" alt="todo some meaningful alt text!"/>
            </a>
        </>
    );
}

export default ViewImages;