import styled from "styled-components";
import Box from "../../Box/Box.styles";
import { LineSplit } from "../../LineSplit/LineSplit.index";
import { H1, H2, H3, H4, H5, A } from "../../Typography/Typography.index";

const HeroSection = styled.div`
    width: 100%;
    max-width: 1200px;
    height: calc(100vh - 4rem);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

	img {
		width: 50%;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		img {
		width: 80%;
		}
	}

	.cursorClass {
		cursor: pointer;
	}
`;

const HomeGlobalStyling = styled.div`

	@media only screen and (max-height: 1000px) {

		.landingSection {
			height: 700px;
		}

		.sectionOne {
			height: 900px;
		}

		.landingSection.End {
			height: calc(700px - 8rem);
		}
	}

	@media only screen and (max-height: 800px) {

		.landingSection {
			height: 600px;
		}

		.sectionOne {
			height: 700px;
		}

		.landingSection.End {
			height: calc(600px - 8rem);
		}
	}
`;

function sectionOneScroll() {
    const firstTarget = document.getElementById("sectionOne");
    firstTarget?.scrollIntoView({behavior: "smooth"});
}

function Home() {
    return (
		<>
			<HomeGlobalStyling>
				<HeroSection className="landingSection sectionOne">
				<img src="./images/hero-test.png" alt="hero"/>
					<Box>
						<H1>ffetch.ai</H1>
						<H5 align="center" letterSpacing="5px">fashion designs by a.i.</H5>
						<LineSplit/>
						<A to="/text-input">
							<H4 align="center">✨ Design now ✨</H4>
						</A>
						<H4 align="center" className="cursorClass" onClick={sectionOneScroll}>Learn More...</H4>
					</Box>
				</HeroSection>
				<Box id={"sectionOne"} className="landingSection">
					<H3>Section One</H3>
				</Box>
				<Box className="landingSection">
					<H3>Section Two</H3>
				</Box>
				<Box className="landingSection">
					<H3>Section Three</H3>
				</Box>
				<Box className="landingSection end">
					<H3>Section Four</H3>
					<A to="/text-input">
						<H2>Design now →</H2>
					</A>
				</Box>
			</HomeGlobalStyling>
		</>
    );
}

export default Home;