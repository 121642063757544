import styled from "styled-components";

export const FormContainer = styled.div`
	width: fit-content;
	padding: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: ${props => props.theme.colors.greyscale.G10};
	border-radius: 1rem;
	box-shadow: 8px 8px 20px #00000060;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;

	input::placeholder {
		margin-left: 1rem;
		text-indent: 1rem;
	}

	div {
		display: flex;
		flex-direction: row;
	}

	input {
		width: 30rem;
		height: 3rem;
		margin: 1rem;
		background-color: transparent;
		border: none;
		border-bottom: 0.5px solid ${props => props.theme.colors.border};
		transition: all 0.5s ease-in-out;
		font-size: 1.5rem;
		font-weight: 100;
		color: ${props => props.theme.colors.text.primary};
		caret-color: ${props => props.theme.colors.text.primary};
		&:focus {
			outline: none;
		}
	}
`;
