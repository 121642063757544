import { Avatar } from "../Avatar/Avatar.index";
import { A, H4, H5, P } from "../Typography/Typography.index";
import { ItemDivStyles, MenuFooterStyles, OverlayStyles, MenuWindowStyles } from "./MenuWindow.styles";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../InitFirebase";
import { MenuIconStyles } from "./MenuIcon.styles";
import { useEffect, useState } from "react";
import { Username } from "../Username/Username.index";
import { Button } from "../Button/Button.index";

function MenuWindow() {
    const [user] = useAuthState(auth as any)
    const [menuIsShowing, setMenuIsShowing] = useState<boolean>(false);

    useEffect(() => {
        const MenuIcon = document.getElementById("menu-icon");
        const IconLineOne = document.getElementById("line-one");
        const IconLineTwo = document.getElementById("line-two");
        const MenuWindow = document.getElementById("mainMenu");
        const MenuOverlay = document.getElementById("menu-overlay");

        if (menuIsShowing) {
            console.log("making menu visible");
            MenuWindow?.classList.add("open");
            MenuOverlay?.classList.add("open");
            MenuIcon?.classList.add("open");
            IconLineOne?.classList.add("active");
            IconLineTwo?.classList.add("active");
            document.body.style.overflow = 'hidden';
        } else {
            console.log("making menu invisible");
            MenuWindow?.classList.remove("open");
            MenuOverlay?.classList.remove("open");
            MenuIcon?.classList.remove("open");
            IconLineOne?.classList.remove("active");
            IconLineTwo?.classList.remove("active");
            document.body.style.overflow = 'unset';
        }
    }, [menuIsShowing]);
    
    function Overlay() {
        return (
            <OverlayStyles id="menu-overlay" onClick={() => setMenuIsShowing(false)} className=""/>
        )
    }

    function Arrow() {
        const itemArrow = ">";
        return (
            <H5 weight="100">{itemArrow}</H5>
        )
    }

    interface MenuItemProps {
        url: string;
        text: string;
    }

    function MenuItem(props: MenuItemProps) {
        return (
            <ItemDivStyles className="menu-item" onClick={() => setMenuIsShowing(false)}>
                <A to={props.url}>
                    <H4>
                        {props.text}
                    </H4>
                    <Arrow/>
                </A>
            </ItemDivStyles>
        )
    }

    function UserGreeting() {
        return (
            <>
                <Avatar/>
                <P px="2rem" weight="bold">Hey <Username/> 👋</P>
            </>
        )
    }

    function MenuFooter() {
        return (
            <MenuFooterStyles>
                {user ? <UserGreeting/> : <Button title="Login"/>}
            </MenuFooterStyles>
        )
    }

    return (
        <>
            {/* "MenuIconStyles" is the burger icon here :) */}
            <MenuIconStyles onClick={() => setMenuIsShowing(!menuIsShowing)} id="menu-icon">
                <div className="line lineOne" id="line-one"></div>
                <div className="line lineTwo" id="line-two"></div>
            </MenuIconStyles> 
            <Overlay/>
            <MenuWindowStyles id="mainMenu" className="">
                <MenuItem url="/" text="🏡 Home"/>
                <MenuItem url="/text-input" text="✨ Create"/>
                <MenuItem url="/about" text="🤓 Learn More"/>
                <MenuItem url="/track-order" text="📭 Track"/>
                <MenuItem url="/faq" text="🤔 FAQs"/>
                <MenuFooter/>
            </MenuWindowStyles>
        </>
    );
}

export default MenuWindow;
