import styled from "styled-components";

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20rem;
    width: 100%;
    max-width: 1200px;
`;

export const FooterTopSection = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
`;

export const FooterControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 350px;
`;

