// A component to cusomtize the garment

import {useEffect, useState} from "react";
import {getDownloadUrl} from "../../../Helpers";
import {httpsCallable} from "firebase/functions";
import {db, functions} from "../../../InitFirebase";
import {PageTitle} from "../../PageTitle/PageTitle.index";
import {PrintfulMockupResult} from "../../../Interfaces";
import {GetMockupImagesRequest} from "../../../../functions/src/FirebaseFunctionInterfaces";
import {
    getHoodieVariantNumber, getTShirtVariantNumber,
    hoodieProductId,
    tShirtProductId, VariantDescription,
    Color, Size, ProductChoice
} from "../../../SupportedProducts";
import {doc, getDoc} from "firebase/firestore";

interface GarmentConfig {
    product: ProductChoice;
    color: Color;
    size: Size;
}

const defaultGarmentConfig: GarmentConfig = {
    product: "hoodie",
    color: "black",
    size: "S"
}

// TODO WORK OUT HOW TO PASS THE CONFIG TO THE MOCKUP GENERATOR
// TODO WORK OUT HOW TO SAVE AND RECALL ALL THE MOCKUPS THAT WE GENERATE, SO WE CAN REUSE THEM
// TODO WORK OUT HOW TO ACTUALLY PLACE A FUCKING ORDER!

interface GarmentConfigProps {
    chosenImage: string;
    onUpdateCallback: (newConfig: any) => void;
}

// Choice for hoodie or t-shirt, colour and size.
function GarmentConfigurationComponent(props: GarmentConfigProps) {

    function getCurrentProductConfig(): GarmentConfig {
        const garmentType = document.getElementById("garmentType") as HTMLInputElement;
        const garmentColour = document.getElementById("garmentColour") as HTMLInputElement;
        const garmentSize = document.getElementById("garmentSize") as HTMLInputElement;
        return {
            product: garmentType.value as ProductChoice,
            color: garmentColour.value as Color,
            size: garmentSize.value as Size
        };
    }

    function onFormChange() {
        props.onUpdateCallback(getCurrentProductConfig());
    }

    useEffect(() => {
        onFormChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSubmitClicked() {
        const config = getCurrentProductConfig();

        console.log("You want a " + config.product + " in " + config.color + " and size " + config.size + " with image " + props.chosenImage);

        const queryString = "?img=" + props.chosenImage + "&type=" + config.product + "&colour=" + config.color + "&size=" + config.size;
        window.location.href = "/checkout" + queryString;
    }

    return (
        <>
            <form onChange={onFormChange}>
                <label>
                    Garment:
                    <select id={"garmentType"}>
                        <option value="hoodie">Hoodie</option>
                        <option value="t-shirt">T-Shirt</option>
                    </select>
                </label>
                <label>
                    Colour:
                    <select id={"garmentColour"}>
                        <option value="black">Black</option>
                        <option value="white">White</option>
                    </select>
                </label>
                <label>
                    Size:
                    <select id={"garmentSize"}>
                        <option value="S">Small</option>
                        <option value="M">Medium</option>
                        <option value="L">Large</option>
                        <option value="XL">X-Large</option>
                        <option value="2XL">XX-Large</option>
                    </select>
                </label>
            </form>
            <button onClick={onSubmitClicked}>Checkout
            </button>
        </>
    );
}

function CustomizeGarment() {
    const queryParams = new URLSearchParams(window.location.search)
    const productUuid = queryParams.get("id") as string;

    const [mockupLoaded, setMockupLoaded] = useState<boolean>(false);
    const [loadingMockup, setLoadingMockup] = useState<boolean>(false);
    const [mockupUrls, setMockupUrls] = useState<string[]>([]);
    const [garmentConfig, setGarmentConfig] = useState<GarmentConfig>(defaultGarmentConfig);
    const [mainImageUrl, setMainImageUrl] = useState<string>("");

    useEffect(() => {
        function startMockupTask(image_url: string) {
            if (!mockupLoaded) {
                const getMockupImages = httpsCallable(functions, "getMockupImages");
                setLoadingMockup(true);
                const isHoodie = garmentConfig.product === "hoodie";
                const color = garmentConfig.color;
                const size = garmentConfig.size;
                const desc: VariantDescription = {color: color, size: size};
                const args: GetMockupImagesRequest = {
                    product_id: isHoodie ? hoodieProductId : tShirtProductId,
                    image_url: image_url,
                    variant_ids: [isHoodie ? getHoodieVariantNumber(desc) : getTShirtVariantNumber(desc)]
                }
                getMockupImages(args).then((response: any) => {
                    const result = response.data as PrintfulMockupResult;
                    console.log(response);
                    if (result.status !== "completed") {
                        // TODO: handle error
                        console.log("Task failed!");
                        return;
                    }

                    let mockupUrls: string[] = [];
                    for (let i = 0; i < result.mockups.length; i++) {
                        mockupUrls.push(result.mockups[i].mockup_url);
                    }
                    setMockupUrls(mockupUrls);
                    setLoadingMockup(false);
                    setMockupLoaded(true);
                });

            }
        }

        if (productUuid && !mockupLoaded) {
            // get product info from firestore
            getDoc(doc(db, "products", productUuid)).then((doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    if (data) {
                        const imageUrl = data.image_url ? data.image_url : data.temp_image_url;
                        if (imageUrl) {
                            const actualImageUrl = getDownloadUrl(imageUrl);
                            console.log("Got image url: " + actualImageUrl);
                            setMainImageUrl(actualImageUrl);
                            startMockupTask(actualImageUrl);
                            setMockupLoaded(true);
                        }
                    }
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mockupLoaded, setMockupUrls, garmentConfig]);

    function onConfigChange(args: GarmentConfig) {
        setGarmentConfig(args);
        setMockupLoaded(false);
        console.log("Config changed!", args);
    }

    function MockupImage() {
        if (loadingMockup) {
            return <div>Loading mockup...</div>
        } else {
            return (
                <div>
                    {mockupUrls.map((url) => {
                        return <img width="256" key={url} src={url} alt="Mockup"/>
                    })}
                </div>
            );
        }
    }

    return (
        <>
            <PageTitle title={'customise garment'}/>
            <img width="512" alt={productUuid} src={mainImageUrl}/>
            <MockupImage/>
            <GarmentConfigurationComponent chosenImage={productUuid} onUpdateCallback={onConfigChange}/>
        </>
    );
}

export default CustomizeGarment;
