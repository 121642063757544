export const tShirtProductId = 71;
export const hoodieProductId = 146;

export type ProductChoice = "t-shirt" | "hoodie";
export type Size = "S" | "M" | "L" | "XL" | "2XL" | "3XL";
export type Color = "white" | "black";
type ColorMap = Map<Color, number>;
type SizeMap = Map<Size, number>;

export interface VariantDescription {
    color: "black" | "white";
    size: Size;
}

// create a map between the size and number such that S=>0, M=>1, L=>2, XL=>3, 2XL=>4, 3XL=>5
const sizeMap = new Map<Size, number>([
    ["S", 0],
    ["M", 1],
    ["L", 2],
    ["XL", 3],
    ["2XL", 4],
    ["3XL", 5],
]);

function getVariantNumberFromParams(desc: VariantDescription, colorMap: ColorMap, sizeMap: SizeMap): number {
    const colorNumber = colorMap.get(desc.color);
    const sizeNumber = sizeMap.get(desc.size);
    if (colorNumber === undefined || sizeNumber === undefined) {
        throw new Error("Invalid color or size");
    }
    return colorNumber + sizeNumber;
}

export function getTShirtVariantNumber(desc: VariantDescription) {
    if (desc.size === "3XL") {
        throw new Error("3XL is not available for t-shirts");
    }

    // Color maps should be the variant number of the smallest size of the colour.
    const colourMap = new Map<Color, number>([
        ["white", 4011],
        ["black", 4016],
    ]);
    return getVariantNumberFromParams(desc, colourMap, sizeMap);
}

export function getHoodieVariantNumber(desc: VariantDescription) {
    // Color maps should be the variant number of the smallest size of the colour.
    const colourMap = new Map<Color, number>([
        ["white", 5522],
        ["black", 5530],
    ]);
    return getVariantNumberFromParams(desc, colourMap, sizeMap);
}

export function getAllTShirtColourGroups() {
    let colourGroups: Color[][] = [];
    for (const colour of ["white", "black"] as Color[]) {
        colourGroups.push([colour]);
    }
    return colourGroups;
}

export function getAllTShirtVariants(): number[] {
    const variants: number[] = [];
    for (const color of ["white", "black"] as Color[]) {
        for (const size of ["S", "M", "L", "XL", "2XL"] as Size[]) {
            variants.push(getTShirtVariantNumber({color, size}));
        }
    }
    return variants;
}

export function getAllHoodieVariants(): number[] {
    const variants: number[] = [];
    for (const color of ["white", "black"] as Color[]) {
        for (const size of ["S", "M", "L", "XL", "2XL", "3XL"] as Size[]) {
            variants.push(getHoodieVariantNumber({color, size}));
        }
    }
    return variants;
}