// Component that takes text input

import {auth, functions} from "../../../InitFirebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {useState, KeyboardEvent} from "react";
import uuid from "react-uuid";
import {httpsCallable} from "firebase/functions"
import styled from "styled-components";
import SpinningLoadingIcon from "../../SpinningIcon/SpinningIcon.index";
import {AiTextInput} from "./AiTextInput.styles";
import {AiInputContainer} from "./AiTextInput.styles";
import {AiImageInfo} from "../../../Interfaces";


const SelectImageParent = styled.div`
  width: 100%;
  margin: 3rem;

  img {
    width: 30%;
    min-width: 350px;
    box-shadow: 5px 5px 15px #00000050;
    transition: all 0.15s ease-in-out;
    margin: 1rem;
  }

  img:hover {
    scale: 1.02;
  }

  img:active {
    scale: 1;
  }
`;

const SelectImageChild = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 678px) {
    flex-direction: column;
  }

  a {
    display: flex;
    justify-content: center;
  }
`;

const textBoxName = "PromptBox"; // todo move this to something sensible

interface DisplayImagesProps {
    imageUrls: AiImageInfo[];
}

function DisplayImages(imagesProps: DisplayImagesProps) {
    const imageUrls = imagesProps.imageUrls;

    const imageElements = imageUrls.map((aiImage) => {
        return (
            <a href={"/customize-garment?id=" + aiImage.id}>
                <img alt={aiImage.prompt} key={aiImage.id} src={aiImage.url}/>
            </a>
        )
    });

    return (
        <SelectImageParent>
            <SelectImageChild>
                {imageElements}
            </SelectImageChild>
        </SelectImageParent>
    );
}

function TextInput() {
    const [user] = useAuthState(auth as any)

    const [imageUrls, setImageUrls] = useState<AiImageInfo[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    async function generatesResponse(prompt: string, promptUuid: string): Promise<any> {
        const generateImagesFunction = httpsCallable(functions, "generateImages");
        return generateImagesFunction({
            prompt: prompt,
            prompt_uuid: promptUuid,
            user_uuid: user?.uid,
            num_images: 2
        });
    }

    function submitText() {

        const textBody = document.getElementById(textBoxName) as HTMLInputElement;
        const text = textBody.value;

        setIsLoading(true);
        setErrorMessage("");

        const uuidString = uuid();
        generatesResponse(text, uuidString).then((response) => {
            console.log(response);
            const imageUrls = response.data as AiImageInfo[];
            setImageUrls(imageUrls);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            setErrorMessage(error.message);
            setIsLoading(false);
        });
    }

    function onEnterPressed(event: KeyboardEvent<HTMLTextAreaElement>) {
        if (event.key === "Enter") {
            submitText();
        }
    }

    function DisplayErrorMessage() {
        if (errorMessage === "") {
            return null;
        }

        return (
            <div>
                <h2>Oops! Looks like something went wrong... </h2>
                <h2> 😢Check your connection and try again! </h2>
                <h3>Error: {errorMessage}</h3>
            </div>
        );
    }

    /* TEXT BOX BRAINS */

    const [count, setCount] = useState(0);

    function calculateAiInputFontSize() {
        const dynamicTextInput = document.getElementById(textBoxName) as HTMLElement;
        if (!dynamicTextInput) {
            return;
        }

        const textInputWidth = dynamicTextInput.offsetWidth;
        const textInputHeight = dynamicTextInput.offsetHeight;

        const textInputArea = textInputWidth * textInputHeight;
        const fontSize = textInputArea / 4000;

        const calculatedFontSize = (Math.floor(fontSize - (count * 0.9))) + "px";
        return calculatedFontSize;
    }

    window.addEventListener("resize", calculateAiInputFontSize);

    const placeholderText = "type something...";

    return (
        <>
            <AiInputContainer>
                <AiTextInput
                    id={textBoxName}
                    autoFocus
                    placeholder={placeholderText}
                    required
                    maxLength={240}
                    onChange={e => setCount(e.target.value.length)}
                    fontSize={"2rem"}
                    onKeyDown={onEnterPressed}
                />
                <button onClick={submitText}>🚀</button>
                <DisplayErrorMessage/>
                {isLoading ? <SpinningLoadingIcon/> : <DisplayImages imageUrls={imageUrls}/>}
            </AiInputContainer>
        </>
    );
}

export default TextInput;