// Theme and mood board

import { Form } from "../Form/Form.styles";
import { FormContainer } from "../Form/Form.styles";
import { H1, H2, H3, H4, H5, H6, P, A, Li } from "../Typography/Typography.index";
import { Spacer } from "../Spacer/Spacer.styles";
import Box from "../Box/Box.styles";
import { ChartTitle, ChartData, ChartHead } from "../SizeChart/SizeChart.index";
import { ChartContainerStyles, ChartStyles } from "../SizeChart/SizeChart.styles";
import { SubHeadOne } from "../SubHeading/SubHeading.index";
import { Button } from "../Button/Button.index";

function ThemeBoard() {

    return (
        <>
            <H1>The quick brown fox jumps over the lazy dog</H1>
            <H2>The quick brown fox jumps over the lazy dog</H2>
            <H3>The quick brown fox jumps over the lazy dog</H3>
            <H4>The quick brown fox jumps over the lazy dog</H4>
            <H5>The quick brown fox jumps over the lazy dog</H5>
            <H6>The quick brown fox jumps over the lazy dog</H6>
            <Spacer height="1rem"/>
            <P >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam obcaecati necessitatibus dolore sed harum sapiente nam corporis pariatur! Tenetur numquam inventore consectetur hic sit eum, saepe vitae nesciunt labore officiis?</P>
            <Spacer height="3rem"/>
            <Box 
                display="flex"
                width="100%"
                direction="row"
                justify="space-evenly"
                >
                    <Button title="Basic"/>
                    <Button title="Warning" design="warning"/>
                    <Button title="Call to Action" design="cta"/>
                    <Button title="Success" design="success"/>
                </Box>
            <Spacer height="3rem"/>
            <Box
                display="flex"
                direction="row"
                justify="space-evenly"
                align="center"
                >
                <A 
                    to="/theme-board">Hello link!
                </A>
                <ul>
                    <Li>list item</Li>
                    <Li>list item</Li>
                    <Li>list item</Li>
                </ul>
                <ol>
                    <Li>list item</Li>
                    <Li>list item</Li>
                    <Li>list item</Li>
                </ol>
            </Box>
            <Spacer height="5rem"/>
            <ChartContainerStyles>
                <ChartStyles>
                    <ChartTitle title="Premium super cool t-shirt"/>
                    <tbody>
                        <tr>
                            <ChartHead data={"Size"}/>
                            <ChartData data={"XS"}/>
                            <ChartData data={"S"}/>
                            <ChartData data={"M"}/>
                            <ChartData data={"L"}/>
                            <ChartData data={"XL"}/>
                            <ChartData data={"2XL"}/>
                            <ChartData data={"3XL"}/>
                            <ChartData data={"4XL"}/>
                            <ChartData data={"5XL"}/>
                        </tr>
                        <tr>
                            <ChartHead data={"Length"}/>
                            <ChartData data={"27"}/>
                            <ChartData data={"28"}/>
                            <ChartData data={"29"}/>
                            <ChartData data={"30"}/>
                            <ChartData data={"31"}/>
                            <ChartData data={"32"}/>
                            <ChartData data={"33"}/>
                            <ChartData data={"34"}/>
                            <ChartData data={"35"}/>
                        </tr>
                        <tr>
                            <ChartHead data={"Width"}/>
                            <ChartData data={"16.5"}/>
                            <ChartData data={"18"}/>
                            <ChartData data={"20"}/>
                            <ChartData data={"22"}/>
                            <ChartData data={"24"}/>
                            <ChartData data={"26"}/>
                            <ChartData data={"28"}/>
                            <ChartData data={"30"}/>
                            <ChartData data={"31"}/>
                        </tr>
                        <tr>
                            <ChartHead data={"Chest"}/>
                            <ChartData data={"31-34"}/>
                            <ChartData data={"35-37"}/>
                            <ChartData data={"38-41"}/>
                            <ChartData data={"42-45"}/>
                            <ChartData data={"46-49"}/>
                            <ChartData data={"50-53"}/>
                            <ChartData data={"54-57"}/>
                            <ChartData data={"58-61"}/>
                            <ChartData data={"62-65"}/>
                        </tr>
                    </tbody>
                </ChartStyles>
            </ChartContainerStyles>
            <SubHeadOne title={"scroll →"} align="right" fontSize="1.25rem"/>
            <Spacer height="5rem"/>
            <div>
                <FormContainer>
                    <Form>
                        <label><H5>GET EXCLUSIVE FREE OFFERS</H5></label>
                        <div>
                            <input type="text" placeholder="name" /*autoFocus*//>
                        </div>
                        <div>
                            <input type="email" placeholder="e-mail"/>
                        </div>
                    </Form>
                </FormContainer>
            </div>
        </>
    );
}

export default ThemeBoard;