import { CustomAvatarProps } from "../../Styles/StyleInterfaces";
import styled from "styled-components";

export const AvatarStyles = styled.img.attrs(() => ({
}))<CustomAvatarProps>`
        width: 5rem;
        height: 5rem;
        border-radius: 20%;
        border: none;
        background-color: transparent;
`;