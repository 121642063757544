// Order success page

import { P } from "../../Typography/Typography.index";
import {PageTitle} from "../../PageTitle/PageTitle.index";

function OrderSuccess() {
    return (
        <>
            <PageTitle title={"Your order has been successful!"}/>
            <P>Thank you for your order!</P>
        </>
    );
}

export default OrderSuccess;