import styled from "styled-components";
import { CustomColorProps, CustomFrameProps, CustomPositionProps, CustomSizeProps, CustomTransitionProps } from "../../Styles/StyleInterfaces";

/**
* House Div
* EXAMPLE USE:
*
*      <Box 
*       display="flex"
*       px="1rem"
*       color="red"
*       bgColor="primary">
*          <H1>Hello World!</H1>
*      </Box>
*
* See src/Styles/StyleInterfaces.tsx for more info on props
*/

const Box = styled.div.attrs((props: any) => ({
}))<CustomPositionProps & CustomFrameProps & CustomSizeProps & CustomColorProps & CustomTransitionProps>`
    position: ${props => props.position || "static"};
    border-radius: ${props => props.radius || "0"};
    display: ${props => props.display || "block"};
    flex-direction: ${props => props.direction || "row"};
    justify-content: ${props => props.justify || "center"};
    align-items: ${props => props.align || "center"};
    place-items: ${props => props.place || "normal"};
    flex-wrap: ${props => props.wrap || "nowrap"};
    width: ${props => props.width || "auto"};
    height: ${props => props.height || "auto"};
    padding: ${props => props.p || "0"};
    padding-top: ${props => props.pt || "0"};
    padding-bottom: ${props => props.pb || "0"};
    padding-left: ${props => props.pl || "0"};
    padding-right: ${props => props.pr || "0"};
    padding-inline: ${props => props.px || "0"};
    padding-block: ${props => props.py || "0"};
    margin: ${props => props.m || "0"};
    margin-top: ${props => props.mt || "0"};
    margin-bottom: ${props => props.mb || "0"};
    margin-left: ${props => props.ml || "0"};
    margin-right: ${props => props.mr || "0"};
    margin-inline: ${props => props.mx || "0"};
    margin-block: ${props => props.my || "0"};
    color: ${props => props.color || props.theme.colors.text.primary};
    background: ${props => props.gradient && props.gradient === "appBackground" ? props => props.theme.colors.gradient.appBackground:
                props.gradient};
    background-color:${props => props.bgColor && props.bgColor === "primary" ? props => props.theme.colors.background.primary:
                props => props.bgColor && props.bgColor === "secondary" ? props => props.theme.colors.background.secondary:
                props => props.bgColor && props.bgColor === "contrast" ? props => props.theme.colors.background.contrast: 
                props.bgColor};
    background-image: ${props => props.bgImage ? `url(${props.bgImage})` : null};
    box-shadow: ${props => props.shadow && props.shadow === "small" ? props => props.theme.shadow.small:
                props => props.shadow && props.shadow === "medium" ? props => props.theme.shadow.medium:
                props => props.shadow && props.shadow === "large" ? props => props.theme.shadow.large:
                props.shadow};
    transition-property: ${props => props.transProp ? props.transProp : "none"};
    transition-timing-function: ${props => props.easing && props.easing === "easeIn" ? props => props.theme.transition.easing.easeIn:
                                  props => props.easing && props.easing === "easeOut" ? props => props.theme.transition.easing.easeOut:
                                  props => props.easing && props.easing === "easeInOut" ? props => props.theme.transition.easing.easeInOut:
                                  props => props.easing && props.easing === "linear" ? props => props.theme.transition.easing.linear:
                                  props => props.easing && props.easing === "bezierOne" ? props => props.theme.transition.easing.bezierOne:
                                  props => props.easing && props.easing === "bezierTwo" ? props => props.theme.transition.easing.bezierTwo:
                                  props.easing};
    transition-duration: ${props => props.speed && props.speed === "slow" ? props => props.theme.transition.speed.slow:
                           props => props.speed && props.speed === "medium" ? props => props.theme.transition.speed.medium:
                           props => props.speed && props.speed === "fast" ? props => props.theme.transition.speed.fast:
                           props.speed};
`;
export default Box;