// Order Tracking page

import { P } from "../../Typography/Typography.index";
import { PageTitle } from "../../PageTitle/PageTitle.index";

function TrackOrder() {
	return (
		<>
			<PageTitle title={"track your order"} />
			<P px="1rem">
				Lorem, ipsum dolor sit amet consectetur adipisicing elit.
				Quibusdam ad minima veniam repudiandae ea sunt id reiciendis
				praesentium quia consectetur. Dolores inventore odit explicabo
				iste omnis quam nobis eveniet provident!
			</P>
		</>
	);
}

export default TrackOrder;
