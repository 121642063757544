import styled from "styled-components";

export const OverlayStyles = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 0%;
	height: 100%;
	z-index: 75;
	opacity: 0;
	background-color: ${props => props.theme.colors.background.primary}80;
	backdrop-filter: blur(0.5rem);
	&.open {
		animation: openOverlay 0.3s linear 0.2s 1 forwards normal;
	}
	@keyframes openOverlay {
		0% {width: 0%; opacity: 0;}
		1%{width: 100%; opacity: 0;}
		100% {width: 100%; opacity: 1;}
	}
`;

export const MenuWindowStyles = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 0rem;
	height: 100%;
	overflow: scroll;
	background: linear-gradient(
		90deg,
		${(props) => props.theme.colors.greyscale.G10} 0%,
		${(props) => props.theme.colors.greyscale.G50} 100%
	);
	display: flex;
	overflow-x: hidden;
	flex-direction: column;
	padding-top: 8rem;
	z-index: 100;
	transition: width 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
	box-shadow: ${(props) => props.theme.shadow.large};
	&.open {
		width: 30rem;
	}
	@media only screen and (max-width: 678px) {
		width: 0rem;
		&.open {
			width: 100vw;
		}
	}
	a {
		min-width: 14rem;
		font-weight: ${props => props.theme.font.weight.medium};
		font-size: ${props => props.theme.font.size.sm};
	}
	h4, p {
		white-space: nowrap;
	}
`;

export const ItemDivStyles = styled.div`
	width: 100%;
	padding-block: 1rem;
	padding-inline: 2rem;
	&:hover {
		background-color: ${props => props.theme.colors.greyscale.G50};
		cursor: pointer;
	}
	a {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
`;

export const MenuFooterStyles = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 30rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: auto;
	padding: 2rem;
	@media only screen and (max-device-width: 678px) 
					   and (orientation: landscape), (max-height: 600px) {
		position: static;
	}
`;
