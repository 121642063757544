// Basic theme Toggle

import {useContext} from "react";
import styled from "styled-components";
import {ThemeContextInterface, ThemePreferenceContext} from "../../App";
import { H6 } from "../Typography/Typography.index";

const ThemeToggleContainer = styled.button`
  width: 10rem;
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border: none;
  background: transparent;
  cursor: pointer;

  img {
    width: 25px;
  }
`;

function ThemeToggle() {

    const themeContext = useContext(ThemePreferenceContext) as ThemeContextInterface;
    const theme = themeContext.currentTheme as any;

    function toggleCurrentTheme() {
        // Ok this is confusing, but we're moving /away/ from light theme
        // so we're setting the theme to dark
        const shouldMakeThemeDark = theme.name === "lightTheme";
        themeContext.setUseDarkTheme(shouldMakeThemeDark);
        window.localStorage.setItem("themePreference", shouldMakeThemeDark ? "dark" : "light");
    }

    const themeToggleText = theme.name === "lightTheme" ? "Go dark" : "Go light";

    return (
        <ThemeToggleContainer onClick={() => toggleCurrentTheme()}>
            <img alt="Colour theme toggle button" src="./images/LazyThemeToggle.svg"/>
            <H6>{themeToggleText}</H6>
        </ThemeToggleContainer>
    );
}

export default ThemeToggle;