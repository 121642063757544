import styled from "styled-components";

export const HeaderStyles = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100vw;
	height: 4rem;
	z-index: 50;
	background-color: transparent;
`;
