// @ts-ignore
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../InitFirebase";
import { useEffect, useState } from "react";
import { AvatarStyles } from "./Avatar.styles";

export function Avatar() {
	const [user] = useAuthState(auth as any)
	const [userImage, setUserImage] = useState("/images/ffetchCollie.png");

	useEffect(() => {
		if (user) {
		const userPhotoUrl = user.photoURL;
		setUserImage(userPhotoUrl || "/images/ffetchCollie.png");
		}
	}, [user]);

	console.log(userImage)

	return (
		<AvatarStyles src={userImage} alt={user?.displayName as string}/>
	)
}
