import styled from "styled-components";

export const SpinningIconContainer = styled.div`
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const SpinningIconChild = styled.span`
    animation: tempLoader 3s ease-in-out 0s infinite alternate;

    @keyframes tempLoader {
        0% {transform: rotateY(0deg) rotateX(0deg);}
        100% {transform: rotateY(720deg) rotateX(360deg);}
    }
`;