import styled from "styled-components";

export const BackgroundContainer = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: -2;
	background: linear-gradient(
		90deg,
		${props => props.theme.colors.greyscale.G10} 0%,
		${props => props.theme.colors.greyscale.G50} 100%
	);
`;

export const AppContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	min-height: 100vh;
	overflow-x: hidden;
`;

export const MainComponentContainer = styled.div`
	min-height: calc(100vh - 24rem);
	width: 100%;
	max-width: 1200px;
	padding-bottom: 4rem;
`;
