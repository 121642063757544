// FFETCH logo icon

import { Svg } from "./Svg.styles";

function LogoIcon() {
    return (
        <Svg height="4rem" viewBox="0 0 349.02 263.49">
            <path d="M349.02,135.82c-.51,1.83-.97,3.68-1.55,5.48-2.64,8.26-7.74,14.71-14.87,20.1-26.32-19.85-52.63-39.7-79.04-59.62-13.12,9.9-26.09,19.68-39.51,29.8,26.35,19.89,52.48,39.61,78.96,59.6-.85,.69-1.53,1.28-2.24,1.81-11.6,8.75-23.24,17.45-34.76,26.3-1.88,1.45-3.04,1.42-4.91,0-24.68-18.73-49.45-37.35-74.14-56.08-1.86-1.41-2.99-1.47-4.9-.02-24.68,18.74-49.46,37.35-74.14,56.08-1.86,1.41-3.02,1.51-4.92,.05-11.21-8.62-22.55-17.09-33.84-25.61-.98-.74-1.93-1.53-3.16-2.52,26.36-19.9,52.51-39.63,78.94-59.58-3.3-2.5-6.33-4.8-9.37-7.1-9.37-7.07-18.77-14.11-28.1-21.24-1.45-1.1-2.38-1.28-3.96-.08-24.85,18.84-49.77,37.57-74.6,56.43-2.02,1.53-3.21,1.41-5.06-.16-18.27-15.42-18.59-39.92-.17-55.33,10.17-8.51,21.04-16.2,31.64-24.2,29.87-22.56,59.71-45.17,89.68-67.6C149.02,1.83,165.14-1.19,182.27,.4c13.39,1.24,25.02,6.61,35.73,14.76,37.66,28.69,75.53,57.12,113.35,85.6,8.84,6.65,15.37,14.75,17.32,25.96,.03,.16,.24,.29,.36,.43v8.67Zm-135.01-63.87c-13.3-10.03-26.39-19.91-39.51-29.81-13.22,9.98-26.24,19.81-39.3,29.67,.38,.45,.53,.71,.76,.88,12.35,9.33,24.73,18.63,37.05,28,1.31,1,2.13,.67,3.24-.18,5.42-4.15,10.88-8.25,16.33-12.36,7.05-5.33,14.11-10.66,21.44-16.19Z"/>
            <path d="M171.7,263.49c-4.9-.79-9.85-1.37-14.69-2.42-6.9-1.5-13.31-4.31-19.24-8.19-.85-.56-1.66-1.19-2.76-1.99,6.55-4.96,12.81-9.71,19.08-14.44,6.21-4.69,12.41-9.41,18.69-14,.71-.52,2.31-.8,2.89-.37,12.72,9.45,25.36,19.01,38.47,28.89-2.61,1.61-4.85,3.16-7.24,4.45-8.32,4.48-17.24,6.92-26.66,7.55-.93,.06-1.84,.35-2.76,.53h-5.78Z"/>
        </Svg>

    );
}

export default LogoIcon;