import styled from "styled-components";

export const JumpArrowContainer = styled.button`
	width: 15rem;
	text-align: center;
	padding: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	border: none;
	background: transparent;
	cursor: pointer;
`;
