// Auto resizing text input for the AI image prompt

import styled from "styled-components";

export const AiInputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-block: 3rem;

    input {
        width: 80%;
        height: 50px;
        font-size: 2rem;
    }

    button {
        width: 8rem;
        height: 3rem;
        margin-top: 3rem;
        font-size: 2rem;
        text-align: center;
    }
`;

export const AiTextInput = styled.textarea.attrs((props: {
    fontSize: string}) => props)`
        width: 100%;
        font-size: ${props => props.fontSize};
        width: 90vw;
        height: 20px
        min-height: 400px;
        border: none;
        resize: none;
        color: ${props => props.theme.colors.text.primary};
        background-color: transparent;
        &:focus {
            outline: none;
            caret-color: fuchsia;
            animation: caretFade 30s infinite;
        }
        @keyframes caretFade {
            0% {caret-color: fuchsia;}
            25% {caret-color: #eeff00;}
            50% {caret-color: #00ffff;}
            75% {caret-color: #00ff04;}
            100% {caret-color: fuchsia;}  
        }
`;