import styled from "styled-components";
import { A } from "../../../Typography/Typography.index";

export const FooterMenuContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem;
`;

export const FooterMenuItem = styled(A)`
	margin: 1rem;
	font-size: 1rem;
`;
