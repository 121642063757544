import React, {useContext, useEffect, useState} from 'react';

// @ts-ignore
import {auth, db} from './InitFirebase.tsx';
import {doc, getDoc} from "firebase/firestore";

// @ts-ignore
const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}: any) {
    const [currentUser, setCurrentUser] = useState(null)
    const [currentUserData, setCurrentUserData] = useState(null)

    const value = {
        currentUser,
        currentUserData,
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: any) => {
            const userId = user?.uid as string
            setCurrentUser(user)

            if (userId) {
                // Get all additional user data
                const docRef = doc(db, "users", userId);
                getDoc(docRef)
                    .then(response => {
                        setCurrentUserData(response.data() as any)
                        console.log("UserInfo", response.data()) // TODO: Remove!?!?!
                    }).catch(error => console.log(error.message))
            }
        })

        // TODO on user info changed.

        return () => {
            unsubscribe()
        }
    }, [])

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}