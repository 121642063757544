import { ButtonStyleProps } from "../../Styles/StyleInterfaces";
import { ButtonStyles } from "./Button.styles";

interface ButtonProps{
    title?: string;
    design?: string;
}

export function Button(props: ButtonProps & ButtonStyleProps) {
    return (
            <ButtonStyles design={props.design}>
                {props.title}
            </ButtonStyles>
    );
}