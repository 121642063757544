import { CustomTextProps } from "../../Styles/StyleInterfaces";
import { SubHeadOneStyles } from "./SubHeading.styles";

interface SubHeadOneProps {
    title: string;
}

export function SubHeadOne(props: SubHeadOneProps & CustomTextProps) {
    return (
        <SubHeadOneStyles {...props}>
            {props.title}
        </SubHeadOneStyles>
    ); 
}