import {
	ChartTitleStyles,
	ChartHeadStyles,
	ChartDataStyles,
} from "./SizeChart.styles";

interface ChartTitleProps {
	title: string;
}

export function ChartTitle(props: ChartTitleProps) {
	return <ChartTitleStyles>{props.title}</ChartTitleStyles>;
}

interface ChartDataProps {
	data: string;
}

export function ChartData(props: ChartDataProps) {
	return <ChartDataStyles>{props.data}</ChartDataStyles>;
}

interface ChartHeadProps {
	data: string;
}

export function ChartHead(props: ChartHeadProps) {
	return <ChartHeadStyles>{props.data}</ChartHeadStyles>;
}
