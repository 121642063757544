// Global color themes 

import {DefaultTheme} from "styled-components";

const baseTheme: DefaultTheme = {
    name: "baseTheme",
    font: {
        family: {
            primary: "Helvetica, Arial, sans-serif",
            secondary: "Arial, Helvetica, sans-serif",
            paragraph: "Montserrat, sans-serif",
        },
        size: {
            xs: "1.25rem",
            sm: "1.5rem",
            rg: "1.35rem",
            md: "2.215rem",
            lg: "3rem",
            xl: "3.37rem",  
            xxl: "6rem",
        },
        weight: {
            extraThin: 100,
            thin: 200,
            light: 300,
            regular: 400,
            medium: 500,
            semiBold: 600,
            bold: 700,
            extraBold: 800,
            heavy: 900,
        },
    },
    shadow: {
        small: "6px 6px 14px #00000080",
        medium: "8px 8px 20px #00000070",
        large: "12px 12px 30px #00000040",
    },
    transition: {
        speed: {
            slow: "0.5s",
            medium: "0.3s",
            fast: "0.1s",
        },
        easing: {
            easeIn: "ease-in",
            easeOut: "ease-out",
            easeInOut: "ease-in-out",
            linear: "linear",
            bezierOne: "cubic-bezier(0.4, 0, 0.2, 1)",
            bezierTwo: "cubic-bezier(0.4, 0, 0.2, 1)",
        },
    },
};

export const lightTheme: DefaultTheme = {
    ...baseTheme,
    name: "lightTheme",
    colors: {
        background: {
            primary: "#f5f5f5",
            secondary: "#ffffff",
            contrast:"#212121",
        },
        text: {
            primary:"#212121",
            contrast: "#fafafa",
        },
        border: {
            primary:"#212121",
            contrast: "#fafafa",
        },
        gradient: {
            appBackground: "linear-gradient(0deg, rgba(250,250,250,1) 0%, rgba(255,255,255,1) 100%)",
        },
        greyscale: {
            G10: "#ffffff",
            G50: "#fafafa",
            G100: "#f5f5f5",
            G200: "#eeeeee",
            G300: "#e0e0e0",
            G400: "#bdbdbd",
            G500: "#9e9e9e",
            G600: "#757575",
            G700: "#616161",
            G800: "#424242",
            G900: "#212121",
            G1000: "#000000",
          },
    },
};

export const darkTheme: DefaultTheme = {
    ...baseTheme,
    name: "darkTheme",
    colors: {
        background: {
            primary: "#212121",
            secondary: "#000000",
            contrast: "#fafafa",
        },
        text: {
            primary:"#fafafa",
            contrast: "#212121",
        },
        border: {
            primary:"#fafafa",
            contrast: "#212121",
        },
        gradient: {
            appBackground: "linear-gradient(0deg, rgba(31,31,31,1) 0%, rgba(0,0,0,1) 100%)",
        },
        greyscale: {
            G10: "#000000",
            G50: "#212121",
            G100: "#424242",
            G200: "#616161",
            G300: "#757575",
            G400: "#9e9e9e",
            G500: "#bdbdbd",
            G600: "#e0e0e0",
            G700: "#eeeeee",
            G800: "#f5f5f5",
            G900: "#fafafa",
            G1000: "#ffffff",
          },
    },
};