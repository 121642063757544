import React, { useState } from "react";

import { DefaultTheme, ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "./Styles/themes";
import GlobalStyle from "./Styles/GlobalStyles";

import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./AuthContent";

import MainComponent from "./Components/Views/MainComponent";
import {AppContainer, BackgroundContainer} from "./Components/AppContainers/AppContainers.styles";
import Header from "./Components/Header/Header.index";
import Footer from "./Components/Footer/Footer.index";
import Login from "./Components/Views/Login/Login";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "./InitFirebase";
import MainMenu from "./Components/MainMenu/MenuWindow.index";
import HelmetMeta from "./Helmet/Helmet.index";

export const ThemePreferenceContext = React.createContext(darkTheme);

export interface ThemeContextInterface {
	currentTheme: DefaultTheme;
	setUseDarkTheme: Function;
}

function App() {
	const [user] = useAuthState(auth as any);

	// This way, just connect any button to setUseDarkTheme and it will change the theme.
	const shouldUseDarkMode = localStorage.getItem("themePreference") === "dark";
	const [useDarkTheme, setUseDarkTheme] = useState<boolean>(shouldUseDarkMode);
	const currentTheme = useDarkTheme ? darkTheme : lightTheme;

	return (
		<AuthProvider>
			<BrowserRouter>
				<HelmetMeta />
				<ThemePreferenceContext.Provider
					value={{ currentTheme, setUseDarkTheme }}>
					<ThemeProvider theme={currentTheme}>
						<GlobalStyle />
						<BackgroundContainer />
						<AppContainer>
							<MainMenu />
							<Header />
							{true ? <MainComponent /> : <Login />} {/*TODO JAMIE REVERT THIS BACK TO user ? <MainComponent /> : <Login />*/}
							<Footer />
						</AppContainer>
					</ThemeProvider>
				</ThemePreferenceContext.Provider>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
