// Sign in using Google's sign in button

import {signInWithPopup, GoogleAuthProvider} from "firebase/auth"
import {auth, db} from "../../InitFirebase";
import {doc, setDoc} from "firebase/firestore";
import GoogleButton from "react-google-button";
import {UserInfo} from "../../Interfaces";
import Box from "../Box/Box.styles";

function SignInButton() {
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider()
        const result = signInWithPopup(auth, provider);
        result.then(() => {
            const userId = auth.currentUser?.uid as string
            const displayName = auth.currentUser?.displayName as string
            const userEmailAddress = auth.currentUser?.email as string

            const userInfo: UserInfo = {
                uid: userId,
                email: userEmailAddress,
                display_name: displayName,
                username: "",
                last_login: {},
            }

            // Add or update user data.
            const userDataRef = doc(db as any, 'users', userId)
            setDoc(userDataRef, userInfo, {merge: true}).catch(error => {
                console.log(error)
            });
        })
    }

    return (
        <Box 
            display="flex"
            justify="center"
            align="center"
            width="100%"
            height="100%"
            >
            <GoogleButton onClick={signInWithGoogle}> </GoogleButton>
        </Box>
    );
}

export default SignInButton;