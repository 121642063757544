import {useState, useEffect} from "react";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../InitFirebase";
import {PrintfulProduct} from "../../../Interfaces";
import { PageTitle } from "../../PageTitle/PageTitle.index";
import styled from "styled-components";
import { P } from "../../Typography/Typography.index";
import { Spacer } from "../../Spacer/Spacer.styles";

const ProductBrowserContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: 678px) {
    flex-direction: column;
  }
`;

const ProductBrowserCard = styled.a`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  margin: 1rem;
  border-radius: 10px;
  min-height: 375px;
  background-color: ${props => props.theme.colors.greyscale.G100};
  text-decoration: none;

  @media only screen and (max-width: 678px) {
    width: 80%;
  }
`;

const ProductBrowserImage = styled.img`
  box-shadow: ${props => props.theme.shadow.medium};
`;

const ProductBrowserCardInfo = styled.div`
  padding-inline: 3rem;
  padding: 2rem;
  font-size: medium;
`;

function ProductBrowser() {

    const [products, setProducts] = useState([]);

    useEffect(() => {
        const getAllPrintfulProducts = httpsCallable(functions, "getAllPrintfulProducts");
        getAllPrintfulProducts({}).then((response: any) => {
            const result = response.data.result;

            // remove all items that are not T-SHIRTS
            const filtered = result.filter((item: PrintfulProduct) => {
                return item.type === "T-SHIRT";
            });
            setProducts(filtered);
        });
    }, []);


    return (
        <>
            <PageTitle title="Product Browser"/>
            <ProductBrowserContainer>
                {products.map((product: PrintfulProduct) => {
                    return (
                        <ProductBrowserCard href={"/product-viewer?product=" + product.id} key={product.type}>
                            <ProductBrowserImage src={product.image} width="256" alt={product.title}/>
                            <ProductBrowserCardInfo>{
                                <>
                                    <P>{product.title}</P>
                                    <Spacer height="1rem"/>
                                    <P weight='bold' align="right">{"Product ID: " + product.id}</P>
                                </>
                            }</ProductBrowserCardInfo>
                        </ProductBrowserCard>
                    )
                })}
            </ProductBrowserContainer>
        </>
    );
}

export default ProductBrowser
