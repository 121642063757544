// Basic login page for ffetch.ai website

import SignInButton from "../../SignInButton/SignInButton.index";
import styled from "styled-components";

const LoginPageContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  flex: 1 1 auto;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Login() {
    return (
        <LoginPageContainer>
            <SignInButton/>
        </LoginPageContainer>
    );
}

export default Login;
