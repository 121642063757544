import { PageTitleStyles } from "./PageTitle.styles";

interface PageTitleProps{
    title?: string;
}

export function PageTitle(props: PageTitleProps) {
    return (
        <PageTitleStyles>
            {props.title}
        </PageTitleStyles>
    );
}