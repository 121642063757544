import styled from "styled-components";
import { Caption, Th, Td } from "../Typography/Typography.index";

const cellPadding = "0.55rem";
const cellMinWidth = "10rem";

export const ChartContainerStyles = styled.div`
    overflow-x: scroll;
    margin-left: 2rem;
`;

export const ChartDataStyles = styled(Td)`
    min-width: ${cellMinWidth};
    padding: ${cellPadding};
    border-left: 0.5px solid ${props => props.theme.colors.border.primary};
`;

export const ChartStyles = styled.table`
    margin-left: auto;
    text-align: left;
    border-collapse: collapse;
`;

export const ChartTitleStyles = styled(Caption)`
    width: fit-content;
    position: sticky;
    padding: ${cellPadding};
    left: 0;
    background-color: ${props => props.theme.colors.greyscale.G1000};
    color: ${props => props.theme.colors.text.contrast};
`;

export const ChartHeadStyles = styled(Th)`
    position: sticky;
    left: 0;
    min-width: ${cellMinWidth};
    padding: ${cellPadding};
    z-index: 1;
    background-color: ${props => props.theme.colors.greyscale.G600};
    text-align: right;
    padding-right: 1rem;
    color: ${props => props.theme.colors.text.contrast};
`;



