import { P } from "../../Typography/Typography.index";
import { PageTitle } from "../../PageTitle/PageTitle.index";
import { SubHeadOne } from "../../SubHeading/SubHeading.index";
import { Spacer } from "../../Spacer/Spacer.styles";

function Faq() {
	return (
		<>
			<PageTitle title={"FAQ"}/>
			<SubHeadOne title={"What is FFETCH?"}/>
			<P indent={true} px="1rem">
				Lorem, ipsum dolor sit amet consectetur adipisicing elit.
				Quibusdam ad minima veniam repudiandae ea sunt id reiciendis
				praesentium quia consectetur. Dolores inventore odit explicabo
				iste omnis quam nobis eveniet provident!
			</P>
			<Spacer height="2rem" />
			<SubHeadOne title={"Where is FFETCH?"}/>
			<P indent={true} px="1rem">
				Lorem, ipsum dolor sit amet consectetur adipisicing elit.
				Quibusdam ad minima veniam repudiandae ea sunt id reiciendis
				praesentium quia consectetur. Dolores inventore odit explicabo
				iste omnis quam nobis eveniet provident!
			</P>
		</>
	);
}

export default Faq;
