import Box from "../../Box/Box.styles";
import { H1, H4 } from "../../Typography/Typography.index";
import { Spacer } from "../../Spacer/Spacer.styles";

function NotFound() {
	return (
		<>
            <Spacer height="5rem"/>
            <Box>
                <H1 weight="heavy">404</H1>
                <H4>Page not found 🫠</H4>
            </Box>
        </>
	);
}

export default NotFound;