import { Link } from "react-router-dom";
import styled from "styled-components";
import { CustomTextProps } from "../../Styles/StyleInterfaces";

const globalIndent = "4rem";

export const H1 = styled.h1.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.xxl};
	font-weight: ${props => props.weight || props.theme.font.weight.light};
	line-height: ${props => props.lineHeight || 1.167};
	letter-spacing: ${props => props.letterSpacing || "-0.01562em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	
`;

export const H2 = styled.h2.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.xl};
	font-weight: ${props => props.weight || props.theme.font.weight.regular};
	line-height: ${props => props.lineHeight || 1.2};
	letter-spacing: ${props => props.letterSpacing || "-0.00833em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
`;

export const H3 = styled.h3.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.lg};
	font-weight: ${props => props.weight || props.theme.font.weight.regular};
	line-height: ${props => props.lineHeight || 1.167};
	letter-spacing: ${props => props.letterSpacing || "0em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
`;

export const H4 = styled.h4.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.md};
	font-weight: ${props => props.weight || props.theme.font.weight.regular};
	line-height: ${props => props.lineHeight || 1.215};
	letter-spacing: ${props => props.letterSpacing || "0.00735em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
`;

export const H5 = styled.h5.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.sm};
	font-weight: ${props => props.weight || props.theme.font.weight.regular};
	line-height: ${props => props.lineHeight || 1.334};
	letter-spacing: ${props => props.letterSpacing || "0em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
`;

export const H6 = styled.h6.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.xs};
	font-weight: ${props => props.weight || props.theme.font.weight.medium};
	line-height: ${props => props.lineHeight || 1.6};
	letter-spacing: ${props => props.letterSpacing || "0.0075em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
`;

export const P = styled.p.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.paragraph};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.rg};
	font-weight: ${props => props.weight || props.theme.font.weight.thin};
	line-height: ${props => props.lineHeight || 1.5};
	letter-spacing: ${props => props.letterSpacing || "0.04em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
`;

export const A = styled(Link).attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.rg};
	font-weight: ${props => props.weight || props.theme.font.weight.light};
	line-height: ${props => props.lineHeight || 1.5};
	letter-spacing: ${props => props.letterSpacing || "0.0938em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "1rem"}; // NON-ZERO DEFAULT
	padding-block: ${props => props.py || "0"};
`;

export const Caption = styled.caption.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.rg};
	font-weight: ${props => props.weight || props.theme.font.weight.light};
	line-height: ${props => props.lineHeight || 1.5};
	letter-spacing: ${props => props.letterSpacing || "0.0938em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
`;

export const Th = styled.th.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.xs};
	font-weight: ${props => props.weight || props.theme.font.weight.light};
	line-height: ${props => props.lineHeight || 1.5};
	letter-spacing: ${props => props.letterSpacing || "0.0938em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
`;

export const Td = styled.td.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.primary};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.xs};
	font-weight: ${props => props.weight || props.theme.font.weight.light};
	line-height: ${props => props.lineHeight || 1.5};
	letter-spacing: ${props => props.letterSpacing || "0.0938em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "uppercase"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "0"};
	padding-block: ${props => props.py || "0"};
`;

export const Li = styled.li.attrs({})<CustomTextProps>`
	font-family: ${props => props.theme.font.family.paragraph};
	color: ${props => props.color || props.theme.colors.text.primary};
	font-size: ${props => props.fontSize || props.theme.font.size.rg};
	font-weight: ${props => props.weight || props.theme.font.weight.medium};
	line-height: ${props => props.lineHeight || 1.5};
	letter-spacing: ${props => props.letterSpacing || "0.0938em"};
	text-decoration: ${props => props.decoration || "none"};
	text-shadow: ${props => props.textShadow || "none"};
	text-transform: ${props => props.transform || "none"};
	text-align: ${props => props.align || "left"};
	text-indent: ${props => props.indent === true ? `${globalIndent}` : props.indent || "0"};
	margin-inline: ${props => props.mx || "0"};
	margin-block: ${props => props.my || "0"};
	padding-inline: ${props => props.px || "1rem"}; // NON-ZERO DEFAULT
	padding-block: ${props => props.py || "0"};
`;