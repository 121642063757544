// Basic checkout page

import {getDownloadUrl} from "../../../Helpers";
import {PageTitle} from '../../PageTitle/PageTitle.index';

function Checkout() {
    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search)
    const imageUri = queryParams.get("img") as string;
    const garmentType = queryParams.get("type") as string;
    const garmentColour = queryParams.get("colour") as string;
    const garmentSize = queryParams.get("size") as string;

    // Get the image URL from the image URI
    const imageUrl = getDownloadUrl(imageUri);

    return (
        <>
            <PageTitle title={"checkout"}/>
            <h2>{garmentType}, {garmentSize}, {garmentColour}</h2>
            {/* TODO(Jamie): query images from products database so we can get good alt text */}
            <img width="500" alt={imageUri} src={imageUrl}/>
            <div>
                Card Number: <input/>
                Your nan's name: <input/>
                <button onClick={() => {
                    window.location.replace("/order-success")
                }}>Pay
                </button>
            </div>
        </>
    );
}

export default Checkout;