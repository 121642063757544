// Global CSS stylesheet

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

	*,
	*::before,
	*::after {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html {
		font-size: 62.5%;
	}

	body {
		font-size: 1.6rem;
	}

	:root {
		font-size: 15px;
	}

`;

export default GlobalStyle;
