import SocialBar from "../SocialBar/SocialBar.index";
import FooterMenu from "./Components/FooterMenu/FooterMenu.index";
import ThemeToggle from "../ThemeToggle";
import JumpArrow from "./Components/JumpArrow/JumpArrow.index";
import { LineSplit } from "../LineSplit/LineSplit.index";
import { Spacer } from "../Spacer/Spacer.styles";
import { FooterContainer, FooterControlsContainer, FooterTopSection } from "./Footer.styles";

function Footer() {

    return (
        <FooterContainer>
            <LineSplit/>
            <Spacer height="3rem"/>
            <FooterTopSection>
                <FooterControlsContainer>
                    <ThemeToggle/>
                    <JumpArrow/>
                </FooterControlsContainer>
                <SocialBar/>
            </FooterTopSection>
            <FooterMenu/>
        </FooterContainer>
    );
}

export default Footer;