import styled from "styled-components";

export const MenuIconStyles = styled.div`

  height: 4rem;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 150;
  &.open {
    position: fixed;
  }

  .line {
    width: 50%;
    height: 2px;
    border-radius: 2px;
    border: 1px solid ${props => props.theme.colors.greyscale.G800};
    transition: transform 0.45s ease-in-out; 
  }

  .lineOne {
    transform: translateY(-5px) rotate(0deg);
  }

  .lineTwo {
    transform: translateY(5px) rotate(0deg);
  }

  .lineOne.active {
    transform: translateY(1px) rotate(45deg);
  }

  .lineTwo.active {
    transform: translateY(-1px) rotate(-45deg);
  }
`;