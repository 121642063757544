import LogoIcon from "../SVGs/LogoIcon";
import { SpinningIconContainer, SpinningIconChild } from "./SpinningIcon.styles";

function SpinningIcon() {
    return (
            <SpinningIconContainer>
                <SpinningIconChild>
                    <LogoIcon/>
                </SpinningIconChild>
            </SpinningIconContainer>
    );
}

export default SpinningIcon;