// we need to query the route for the product id, so we import useParams
import {useEffect, useState} from "react";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../InitFirebase";
import {PrintfulProduct, Variant} from "../../../Interfaces";
import {PageTitle} from "../../PageTitle/PageTitle.index";
import styled from "styled-components";
import { H4, H6, P } from "../../Typography/Typography.index";
import { LineSplit } from "../../LineSplit/LineSplit.index";
import { Spacer } from "../../Spacer/Spacer.styles";
import Box from "../../Box/Box.styles";

const ProductViewContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
const ProductViewHeadCard = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 1rem;
    border-radius: 10px;
    min-height: 375px;
    border: 1px solid ${props => props.theme.colors.border};
    background-color:${props => props.theme.colors.greyscale.G50};

    img {
        width: 100%;
        max-width: 375px;
        transition: all 0.2s ease-in-out;
        box-shadow: ${props => props.theme.shadow.medium};
    }

    @media only screen and (max-width: 678px) {
        flex-direction: column;
    }
`;
const VariantContainer = styled.div`
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
`
const ProductViewCard = styled.div`
    width: 40%;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    margin: 1rem;
    border-radius: 10px;
    min-height: 375px;
    background-color:${props => props.theme.colors.greyscale.G100};

    @media only screen and (max-width: 678px) {
        width: 80%;
    }
`;
const ProductViewImage = styled.img`
  box-shadow: 8px 8px 20px #00000060;
`;
const ProductViewCardInfo = styled.a`
    padding-inline: 3rem;
    padding-block: 1rem;
    font-size: medium;
    h2 {
        border: 1px solid ${props => props.theme.colors.border};
        padding: 0.5rem;
        margin: 0.5rem;
    }
    hr {
        width: 100%;
        border-bottom: 0.5px;
        margin-top: 1rem;
    }
`;
const InStockIconContainer = styled.div`
    background-color: green;
    padding: 1rem;
    margin-top: 1rem;
    color: white;
`;
const OutOfStockIconContainer = styled.div`
    background-color: red;
    padding: 1rem;
    margin-top: 1rem;
    color: white; 
`;
const AvailableRegions = styled.div`
  width: 100%;
  line-break: anywhere;
  line-height: 2;
  text-align: center;
  padding: 0.5rem;
`;

function InStockIcon() {
    return (
        <InStockIconContainer>
            IN STOCK
        </InStockIconContainer>
    );
}
function OutOfStockIcon() {
    return (
        <OutOfStockIconContainer>
            OUT OF STOCK
        </OutOfStockIconContainer>
    );
}

function PrintfulProductView() {
    const queryParams = new URLSearchParams(window.location.search)
    const productId = queryParams.get("product") as string;

    const [product, setProduct] = useState<PrintfulProduct>();
    const [variants, setVariants] = useState<Variant[]>([]);

    useEffect(() => {
        console.log(productId);
        const getProduct = httpsCallable(functions, "getPrintfulProduct");
        const getPrintfiles = httpsCallable(functions, "getProductPrintfiles");
        getProduct({product_id: productId}).then(async (response: any) => {
            const result = response.data.result as any;
            setProduct(result.product);

            const variants = result.variants as Variant[];
            variants.sort((a, b) => a.id - b.id);
            setVariants(variants);

            const printfiles = await getPrintfiles({product_id: productId}) as any;
            console.log("Printfiles: ", printfiles);
        });
    }, [productId]);

    // Print the product details at the top of the page, and then list all the variants
    return (
        <>
            <ProductViewContainer>
                <PageTitle title={"Product View"}/>
                <ProductViewHeadCard>
                    <PageTitle title={product?.title}/>
                    <img alt={product?.title} src={product?.image}/>
                    <PageTitle title={"ID - " + product?.id}/>
                </ProductViewHeadCard>
                <PageTitle title={"Available variants"}/>
                {/*<p>{JSON.stringify(product?.options)}</p>*/}
                <VariantContainer>
                    {variants.map((variant: Variant) => {
                            return (
                                <ProductViewCard key={variant.id}>
                                    <ProductViewImage width="256" alt={variant.name} src={variant.image}/>
                                    <ProductViewCardInfo>
                                        <P>{variant.name + " | " + variant.id}</P>
                                        <LineSplit/>
                                        <AvailableRegions>
                                            <H6 letterSpacing="3px">{JSON.stringify(variant.availability_regions)}</H6>
                                        </AvailableRegions>
                                        <H6 align="center">{variant.in_stock ? <InStockIcon/> : <OutOfStockIcon/>}</H6>
                                        <Spacer height="1rem"/>
                                        <Box 
                                            display="flex" 
                                            justify="space-between" 
                                            width="100%">
                                            <H4 weight="bold">{variant.price}</H4>
                                            <H4>{variant.size}</H4>
                                        </Box>
                                    </ProductViewCardInfo>
                                </ProductViewCard>
                            )
                        }
                    )}
                </VariantContainer>
            </ProductViewContainer>
        </>
    );
}

export default PrintfulProductView;